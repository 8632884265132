@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Regular.eot');
  src:
    local('Poppins Regular'),
    local('Poppins-Regular'),
    url('/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Poppins-Regular.woff') format('woff'),
    url('../public/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
